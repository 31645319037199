/*------------------------------------*/
/*///////#testimony-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.testimony-alpha{
	width: 100%;
	background-color: #e4eaf0;
	padding: 40px 0;
	
	.title{
		font-family: $font2;
		font-weight: 400;
		font-size: 2.500em;
		line-height: 1em;
		margin:0;
		margin-top: 43px;
	}

	.img-cont {
		display: inline-block;
		box-shadow: 0px 0px 150px rgba(17, 43, 68, 0.5);
	}

	.shortline {
		width: 100%;
		height: 3px;
		background-color: $blue;
		display: block;
		margin: 20px 0 30px 0;

	}
	.context{
		font-family: $font1;
		font-size: 1.125em;
		margin: 25px 0 40px 0;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.testimony-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.testimony-alpha{
		padding: 140px 0;
		.title{
			font-size: 3.875em;
			margin-top: 0px;
		}
		.pad-left {
			padding-left: 70px;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.testimony-alpha{

	}
}
