/*------------------------------------*/
/*///////#card-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-alpha {
	border:none;
	margin: 0;
	background-color: #f1f1f1;
	position: relative;
	> img {
		width: 100%;
		min-width: 100%;
		height: auto;
		min-height: 200px;
	}
	.card-section {
		padding: 1.500em 1.5em 1.5em;
	}
	.tag-cont {
		margin-top: 0;
	}
	.for-border{
		position: absolute;
		z-index: 9;
		width: 100%;
		height: 100%;
		border: 4px solid $blue; 
		pointer-events: none;
		opacity: 0;
		@include anim();
	}
	&:hover {
		.for-border {
			opacity: 1;
		}
	}
	.button-alpha {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.card-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-alpha {
		.card-section {
			padding: 2.500em 1.875em 1.875em;
		}
		.button-alpha {
			margin-top: 0px;
			margin-bottom: 0px;
		}
		.tag-cont {
			margin-top: 2.500em;;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-alpha{

	}
}
