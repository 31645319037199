/*------------------------------------*/
/*///////#page-arsami-degerlendir ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.page-arsami-degerlendir{
	.btn-cont {
		width: 220px;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.page-arsami-degerlendir{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.page-arsami-degerlendir{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.page-arsami-degerlendir{

	}
}
