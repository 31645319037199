/*------------------------------------*/
/*///////#input-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.input-alpha{
	// height: 70px;
	float: none;
	content: "";
	display: table;
	clear: both;
	position: relative;
	width: 100%;
	margin-bottom: 20px;

	*{
		@include anim();
	}

	input[type=text],
	input[type=email],
	select {
		overflow: hidden;
		font-family: $font2;
		font-size: 1.5em;
		color:$blue;
		box-shadow: none;
		width: 100%;
		height: 70px;
		background-color: $gray !important;
		// border: none;
		border: 1px solid $gray;
		padding: 0.5em 1em 0.4em 1em;
		line-height: 2em;
		&:focus {
			border: 1px solid $blue;
			box-shadow: none;
		}
		&.is-invalid-input, &.is-invalid-input:not(:focus){
			border: 1px solid $alert;
			color: $alert;
			&::placeholder {
				color: $alert;
			}
		}
	}

	textarea {
		overflow: hidden;
		min-height: 7em;
		padding: 0.8em 1em;
		resize: none;
		overflow: hidden;
		font-family: $font2;
		font-size: 1.5em;
		color:$blue;
		box-shadow: none;
		width: 100%;
		height: 70px;
		background-color: $gray !important;
		// border: none;
		border: 1px solid $gray;
		padding: 0.5em 1em;
		line-height: 1.3em;
		&:focus {
			border: 1px solid $blue;
			box-shadow: none;
		}
		&.is-invalid-input, &.is-invalid-input:not(:focus){
			border: 1px solid $alert;
			color: $alert;
			&::placeholder {
				color: $alert;
			}
		}
	}

	input[type=text]::placeholder,
	input[type=email]::placeholder,
	textarea::placeholder {
		color: $blue;
		// padding-left: 1em;
		@include anim();
	}

	input[type=text]:focus::placeholder,
	input[type=email]:focus::placeholder,
	textarea:focus::placeholder {
		color: transparent;
		// padding-left: 0.1em;
		transform: scale(0.9);
	}

	select{
		overflow: hidden;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill:#005ec3'></polygon></svg>");
		background-size: 13px 9px;
		background-position: right -10px center;
		option{
			&:active, &:hover{
				box-shadow: inset 0px 50px $blue;
				color: white;
			}
		}
		&:hover {
			border: 1px solid $blue;
		}
		&.is-invalid-input{
			border: 1px solid $alert;
			color: $alert;
		}
	}
	
	select[multiple]{
		overflow: hidden;
		padding: 0;
		padding-top: 10px;
		min-height: 7em;
		border:none;
		option{
			padding: 0 1em;
			&:selected{
				box-shadow: inset 0px 50px $blue2;
			}
			&:active, &:hover{
				box-shadow: inset 0px 50px $blue;
				color: white;
			}

		}
		&.is-invalid-input{
			border: 1px solid $alert;
			color: $alert;
		}
	}
	
	input[type=radio],
	input[type=checkbox]{
		overflow: hidden;
		outline: none;
		display: none;
		box-shadow: none;
		
		+ label{
			font-family: $font2;
			font-size: 1.4em;
			line-height: 1em;
			margin: 0;
			color:$blue;
			user-select: none;
			// margin-bottom: 10px;
			display: inline-flex;
			align-items: flex-start;
			// margin-right: 2em;
			span{
				display: inline-flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				margin-right: 10px;
				// margin-bottom: -1px;
				width: 20px;
				height: 20px;
				background-color: $gray;
				border: 1px solid $blue;
				position: relative;
				i.active{
					text-align: center;
					opacity: 0;
					font-size: 16px;
					line-height: 1.3em;
					position: absolute;
					left: 1px;
					top: 0px;
				}
				i.icon-nokta{
					top:-1px;
					border-radius: 100%;
				}
			}
		}
		&:focus{
			box-shadow: none;
		}
		&:checked{
			+ label{
				i.active{
					opacity: 1;
				}
			}
		}

		&.is-invalid-input{
			// border: 1px solid $alert;
			+ label {
				color: $alert;
				
			}
		}
		
	}

	input[type=radio]{
		+ label{
			span{
				border-radius: 100%;
			}
		}	
	}

	input[type=file]{
		// display: none;
		position: absolute;
		outline:none; 
		opacity: 0;
		// width: 0;
		// height: 0;
		// opacity: 0;
		+ label{
			width: 100%;
			height: 70px;
			background-color: $gray;
			position: relative;
			margin: 0 0 10px;
			cursor: pointer;
			border: 1px solid transparent;
			
			.forText{
				position: absolute;
				font-family: $font2;
				font-size: 1.5em;
				margin: 0;  
				color:$blue;
				padding: 18px 20px;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;

				&:before{
					content: "";
					width: 13px;
					height: 5px;
					background-color: $blue;
					position: absolute;
					right: 20px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
				&:after{
					content: "";
					width: 13px;
					height: 5px;
					background-color: $blue;
					position: absolute;
					right: 20px;
					top: 0;
					bottom: 0;
					margin: auto;
					transform: rotate(90deg)
				}
			}
			

			&:hover, &:focus{
				border: 1px solid $blue;
			}
		}
		&:hover, &:focus {
			+ label {
				border: 1px solid $blue;
			}
		}
		&.is-invalid-input{
			// border: 1px solid $alert;
			+ label {
				color: $alert;
				border: 1px solid $alert;
				.forText{
					color: $alert;
				}
			}
		}
		
	}
	
	input[type=submit], .button-beta{
		padding: 18px;
	}
	

}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.input-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.input-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.input-alpha{

	}
}
