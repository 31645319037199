/*------------------------------------*/
/*///////#card-epsilon ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-epsilon {
	width: 100%;
	height: 100%;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	// background-attachment: cover;

	a.name {
		text-decoration: none;
		font-size: 2.250em;
		font-weight: 400;
		line-height: 1.1em;
		font-family: $font2;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		cursor: pointer;
		text-align: center;
		@include anim(0.3s);
		&:hover {
			background-color: rgba(0,0,0,0);
			opacity: 0;
			// color: $blue;
			// text-shadow: 0px 0px 4px #fff;
		}
	}
	&:hover {

	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.card-epsilon{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-epsilon{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-epsilon{

	}
}
