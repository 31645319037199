/*------------------------------------*/
/*///////#form-delta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.form-delta{
	.sub-info{
		font-size: 12px;
		font-family: $font1;
		font-weight: 300;
		margin: 30px 0 20px 0;
	}
	.btn-cont{
		margin-top: 20px;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.form-delta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.form-delta{
		margin-top: 50px;
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.form-delta{
		margin-top: 70px;
	}
}
