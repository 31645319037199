/*------------------------------------*/
/*///////#address-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.address-alpha{
	margin-bottom: 50px;
	h5 {
		margin-top: 30px;
		font-family: $font1;
		font-size: 1em;
		font-weight: 600;
		margin-bottom: 0;
	}
	p {
		font-family: $font1;
		font-size: 1em;
		font-weight: 300;
		margin: 0.3em 0;
		b{
			font-weight: 600;
		}
		a{
			text-decoration: none;
			color: $blue;
		}
	}
	.map-cont {
		height: 170px;
	}
	.mail{
		text-decoration: none;
		color: inherit;
		margin-left: 10px;
	}
	.bt-cont{
		margin-top: 20px;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.address-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.address-alpha{
		h5 {
			font-size: 1.25em;
		}
		p {
			font-size: 1.25em;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.address-alpha{
		h5 {
			margin-top: 0px;
		}
	}
}
