/*------------------------------------*/
/*///////#button-alpha ///////////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.button-alpha {
	// position: relative;
	text-decoration: none;
	display: inline-block;
	border: 1px solid $blue;
	font-family: $font2;
	font-size: 1.125em;
	line-height: 1em;
	color: $blue;
	cursor: pointer;
	padding: 12px 18px;
	display: inline-flex;
	// height: 40px;
	align-items: center;
	justify-content: center;
	text-align: center;
	box-sizing: border-box;
	width: 100%;
	@include anim(0.18s);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:hover {
		background-color: $blue;
		color: white;
		// span {
		// 	height: 100%;
		// 	width: 100%;
		// 	left: 0;
		// 	top: 0;
		// 	// opacity: 1;
		// }
	}
	// span {
	// 	// z-index: -1;
	// 	@include anim(0.18s);
	// 	width: 90%;
	// 	height: 2px;
	// 	// border: 1px solid $blue;
	// 	background-color: $blue;
	// 	// opacity: 0;
	// 	position: absolute;
	// 	left: 5%;
	// 	top:100%;
	// }
}
// Usage
/*<-- XS -->*/

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.button-alpha{

	}
}
/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.button-alpha{

	}
}
/*<-- LG -->*/
@media (min-width: $large_bp) {
	.button-alpha{

	}
}
