@import "vars.scss";

.grid-line{
    width: 100%;
    margin:0;
    padding: 0;
    height: 0;
    border:0;
    border-top: 1px solid $gray2;
}

html, body{
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
    -webkit-tap-highlight-color: transparent;
}

body.is-reveal-open {
    // overflow: visible;
    min-width: 100% !important;
    overflow: hidden !important;
    position: relative !important;

}

.reveal-overlay{
    // z-index: 9999;
    // overflow-y: hidden;
    // max-width: 80%;
    // margin: auto;
    .reveal{

    }
}
#formReturn{
    padding: 50px 60px;
    text-align: center;
    border: none;
    .filecv, .warn, .success, .sending, .code {
        display: none;
    }
    
    &.sending{
        .sending{
            display: block;
        }
    }
    &.success{
        .success{
            display: block;
        }
    }
    &.warn{
        .warn{
            display: block;
        }
    }
    &.code{
        .code{
            display: block;
        }
    }
    &.filecv{
        .filecv {
            display: block;
        }
    }
    p { 
        font-family: $font2;
        font-size: 35px;
        font-weight: 600;
        // line-height: 1em;
        color: $blue;
        img{
            margin-top: 10px;
        }
    }
    .warn p {
        color: $alert;
    }
    .close-button{
        font-size: 28px;
    }
    .icon-close {
        color: $blue;
        // overflow: hidden;
        // height: 20px;
        &:before{
            font-size: 20px;
        }
        
    }
}