/*------------------------------------*/
/*///////#nav-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.nav-beta{
	a.navib{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $blue;
		padding: 0 10px;
		text-decoration: none;
		color: $blue;
		font-family: $font2;
		font-weight: 400;
		font-size: 1.125em;
		line-height: 1em;
		width: 100%;
		text-align: center;
		height: 80px;
		// white-space: nowrap;
		@include anim();
		&:hover, &.active{
			background-color: $blue;
			color: #fff;
		}
	}

	.search{
		background-color: $blue;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		width: 100%;
		height: 100%;
		min-height: 80px;
		position: relative;
		padding: 0px 0;
		margin-top: 20px;
		@include anim();
		form{
			width: 100%;
			height: 100%;
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}
		input{
			outline: none;
			border: none;
			background-color: transparent;
			color: #fff;
			font-family: $font2;
			font-weight: 400;
			font-size: 1.250rem;
			text-align: center;
			padding: 20px 20px 20px 0px;
			margin: 0;
			box-shadow: none;
			appearance: none;
			@include anim();
			&:focus{
				outline: none;
				box-shadow: none;
				transition: none;
				// padding-right: 15%;
				+ .search-bt {
					right: 5%;
				}
				&::placeholder {
					opacity: 0;
					transform: scale(0.9);
				}
			}
			&::placeholder {
				@include anim();
				color: #fff;
				font-family: $font2;
				font-weight: 400;
				font-size: 1.250rem;
			}
		}
		.search-bt{
			position: absolute;
			right: 20%;
			top: 30px;
			color: #fff;
			text-decoration: none;
			cursor: pointer;
			@include anim();
			i{
				font-size: 16px;
			}
		}
	}

	.selector {
		padding: 20px;
		font-family: $font2;
		font-weight: 400;
		font-size: 1.250rem;
		border: 1px solid $blue;
		text-decoration: none;
		color: $blue;
		height: 80px;
		margin-bottom: 0;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill:#005ec3'></polygon></svg>");
		background-size: 13px 9px;
		background-position: right 5px center;
	}
	
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.nav-beta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.nav-beta{
		.search{
			margin-top: 0px;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.nav-beta{

	}
}
