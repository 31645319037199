/*------------------------------------*/
/*///////#carousel-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.carousel-alpha {
	position: relative;


	
	
	.swiper-slide {
		position: relative;
        background-size: cover;
        background-repeat: no-repeat;		
		background-position: center center;
		min-height: 460px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include anim();
		&.bg-size-contain{
			background-size: contain;
		}
	}
	
	

	// .swiper-button-next,
	// .swiper-button-prev {
	// 	display: none;
	// }

	.swiper-button-next {
		background-image: url("../svg/arrow-right.svg");
		background-size: 9px 14px;
		// margin-right: 60px;
		display: none;
	}
	.swiper-button-prev {
		background-image: url("../svg/arrow-left.svg");
		background-size: 9px 14px;
		// margin-left: 60px;
		display: none;
	}

	.swiper-pagination {
		// bottom: -20px;
		position: static;
		// margin-left: 10px;
		margin-top: 10px;
		text-align: left;
		.swiper-pagination-bullet {
			border-radius: 0;
			width: 12px;
			height: 12px;
			margin: 0 5px;
			background-color: $darkish;
			opacity: 0.8;
		}
		.swiper-pagination-bullet-active {
			background-color: $blue;
		}
	}

	.pagination-under {
		// bottom: 0;
		position: static;
		// margin-top: 20px;
	}

	.spot-cont {
		margin-left: 20px;
		margin-right: 20px;
		margin: 0;
		text-align: center;
		position: relative;
		@include anim(0.5s);
		&.spotIn {
			// opacity: 1;
			// transform: scale(1);
			.project-spot {
				
				opacity: 1;
				transform: scale(1);
				transition-delay: 0.1s;
			}
			.btn-cont {
				opacity: 1;
				transform: scale(1);
				// transform: rotateX(0deg);
				transition-delay: 0.4s;
				// margin-top: 10px;
			}
		}
		.project-spot {
			// max-width: 90%;
			display: block;
			background-color: rgba(255,255,255,0.8);
			font-family: $font2;
			font-weight: 300;
			font-size: 2.750em;
			color: $blue;
			letter-spacing: 0.001em;
			line-height: 1em;
			padding: 10px;
			user-select: none; 
			margin: 0;
			@include anim(0.7s);
			opacity: 0;
			transform: scale(0.3);
		}
		.btn-cont {
			margin: 10px auto;
			width: 100%;
			@include anim(0.7s);
			opacity: 0;
			transform: scale(0.3);
			// margin-top: 100px;
			// transform: rotateX(90deg);
			// transform: perspective(5px);
		}
	}


	
	
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.carousel-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.carousel-alpha{

		.swiper-slide {
			height: 670px;
		}

		.swiper-button-next,
		.swiper-button-prev {
			pointer-events: visible;
			background-color: #fff;
			width: 50px;
			height: 50px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border-radius: 0;
			@include anim();
		}
		.swiper-button-next {
			margin-right: 20px;
		}
		.swiper-button-prev {
			margin-left: 20px;
		}
		.swiper-slide {
			.spot-cont {
				margin-left: 100px;
				margin-right: 100px;
				.project-spot {
					// max-width: 80%;
					margin: 0 auto;
					padding: 30px; 
					font-size: 5.750em;
				}
				.btn-cont {
					margin: -15px auto;
					// width: 50%;
					min-width: 230px;
				}
			}
		}

		.swiper-pagination {
			bottom: 56px;
			position: absolute;
			margin: 0;
			text-align: center;
			.swiper-pagination-bullet {
				border-radius: 0;
				width: 20px;
				height: 20px;
				margin: 0 10px;
			}
		}
		.pagination-under {
			// bottom: 0;
			position: static;
			margin-top: 20px;
			.swiper-pagination-bullet{
				width: 10px;
				height: 10px;
			}
		}

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.carousel-alpha {
		.swiper-button-next {
			margin-right: 60px;
		}
		.swiper-button-prev {
			margin-left: 60px;
		}
		.spot-cont {
			.button-beta {
				display: inline-flex;
				width: auto;
				min-width: 200px;
				// padding: 0 20px;
			}
			// .project-spot {
			// 	max-width: 90%;
			// }
		}
	}
}