/*------------------------------------*/
/*///////#sitemap-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.sitemap-alpha{
	margin-top: 50px;
	h3 {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 40px;
		min-height: 40px;
		background-color: $blue;
		color: #fff;
		font-family: $font2;
		font-size: 1.875em;
		font-weight: 600;
		margin: 0;
		padding: 0;
	}
	.top-page a {
		text-decoration: none;
		display: block;
		color: $blue;
		font-family: $font2;
		font-size: 1.5em;
		font-weight: 600;
		margin: 1em 0 0.5em;
		padding: 0;
	}
	.page-list{
		list-style: none;
		padding: 0;
		// padding-left: 1em;
		margin: 0;
		li {
			&:before {
				color: $blue;
			}
			a {
				color: black;
				font-family: $font1;
				font-size: 1em;
				font-weight: 400;
				text-decoration: none;
				&:hover{
					color: $blue;
				}
			}
		}
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.sitemap-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.sitemap-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.sitemap-alpha{

	}
}
