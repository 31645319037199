/*------------------------------------*/
/*///////#iso-grid-test ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.iso-grid-alpha{
	.h-1{
		height: 196px;
		margin-bottom: 20px;
	}
	.h-2{
		height: 196px;
		margin-bottom: 20px;
	}
	.row .row{
		margin-left: -10px;
		margin-right: -10px;
	}
	.column {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.iso-grid-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.iso-grid-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.iso-grid-alpha{
		.h-2{
			height: 412px;
			margin-bottom: 20px;
		}
	}
}
