/*------------------------------------*/
/*///////#button-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
button.button-beta{
	display: inline-block;
}
.button-beta{
	// position: relative;
	text-decoration: none;
	display: inline-block;
	background-color: $blue;
	font-family: $font2;
	font-size: 1.25rem;
	color: white;
	cursor: pointer;
	padding: 0.4rem 1.125rem;
	// padding-left: 5;
	// padding-right: 5;
	display: inline-flex;
	// height: 50px;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
	@include anim(0.18s);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&:hover, &:focus {
		background-color: $blue2;
	}

	&.beyaz {
		background-color: #fff;
		color: $blue;
		border: 1px solid #fff;
	}
	&.beyaz:hover{
		background-color: $blue;
		color: #fff;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.button-beta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.button-beta{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.button-beta{

	}
}
