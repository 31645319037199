/*------------------------------------*/
/*///////#isotope-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.isotope-alpha{

	width: 100%;
	height: 100%;
	max-width: 1200;

	.h-1{
		height: 196px;
		margin-bottom: 20px;
	}
	.h-2{
		height: 196px;
		margin-bottom: 20px;
	}
	.row .row{
		margin-left: -10px;
		margin-right: -10px;
	}
	.column {
		padding-left: 10px;
		padding-right: 10px;
	}




	.hide{
		display: none;
	}

	#gridRow {
		margin-top: 20px;
	}
	#listRow{
		margin-top: 10px;
	}
	
	// .grid-row {
	// 	width: 1200px;
	// 	margin: 20px auto 0;
	// 	max-width: 100%;
	// }

	.grid {
		position: relative;
	}
	.item {
		@include anim();
		display: block;
		position: absolute;
		// width: 100%;
		// height: 100%;
		// height: 197px;
		// margin: 5px;
		z-index: 1;
		// background: pink;
		color: #fff;
		margin: 10px;
		max-width: 100%;
		// margin-right: 19px;
		// margin-bottom: 19px;
	}

	.item2-2 {
		width: 575px;
		height: 412px;
	}
	.item2-1 {
		width: 575px;
		height: 197px;
	}
	.item1-1 {
		width: 277px;
		height: 197px;
	}

	// .item.muuri-dragging {
	// 	z-index: 3;
	// }
	// .item.muuri-releasing {
	// 	z-index: 2;
	// }
	// .item.muuri-hidden {
	// 	z-index: 0;
	// }
	.item-content {
		position: relative;
		width: 100%;
		height: 100%;
	}

	
	// .list-type-class {
	// 	.item {
	// 		@include anim();
	// 		position: static;
	// 		display: flex;
	// 		width: 100%;
	// 		height: auto;
	// 		padding: 30px 0;
	// 		border-top: 1px solid #e7e7e7;
	// 		border-bottom: 1px solid #e7e7e7;
	// 		// margin: 0;
	// 		margin: 10px 0;
	// 		.item-content{
	// 			display: inline-flex;
	// 			width: auto;
	// 		}
	// 		.card-epsilon {
	// 			width: 200px;
	// 			height: 112px;
	// 			a.name{
	// 				display: none;
	// 			}
	// 		}
	// 	}
	// }
		
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.isotope-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.isotope-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.isotope-alpha{
		.h-2{
			height: 412px;
			margin-bottom: 20px;
		}
	}
}
