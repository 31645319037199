/*------------------------------------*/
/*///////#tag-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.tag-alpha{
	font-family: $font1;
	font-weight: 400;
	font-size: 0.688em;
	color: white;
	background-color: black;
	text-decoration: none;
	margin-right: 5px;
	padding: 2px 10px;
	display: inline-flex;
	border: 1px solid #d5dfeb;
	@include anim();
	// &:hover {
	// 	background-color: $blue;
	// }
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.tag-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.tag-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.tag-alpha{

	}
}
