/*------------------------------------*/
/*///////#card-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-beta{
	border-bottom: 1px solid $gray3;
	padding: 50px 0;
	
	.img-cont{
		overflow: hidden;
		border-radius: 100%;
		width: 137px;
		height: 137px;
	}
	.name {
		font-family: $font2;
		font-size: 1.500em;
		color: $blue;
		margin: 0;
		margin-top: 25px;
	}
	.comment {
		font-family: $font1;
		font-weight: 300;
		font-size: 1em;
		color: black;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.card-beta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-beta{
		border-bottom: 1px solid $gray3;
		border-top: 1px solid $gray3;
		padding: 30px 0;
		margin: 20px 0;

		// .img-cont{
		// 	overflow: hidden;
		// 	border-radius: 100%;
		// 	width: 137px;
		// 	height: 137px;
		// }
		.name {
			font-family: $font2;
			font-size: 1.500em;
			color: $blue;
			margin: 0;
			// margin-top: 25px;
		}
		.comment {
			font-family: $font1;
			font-weight: 300;
			font-size: 1.2em;
			color: black;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-beta{

	}
}
