/*------------------------------------*/
/*///////#breadcrumb-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.breadcrumb-alpha{
	.bread-list{
		margin:0;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		-webkit-padding-start: 0px;
		padding: 10px 0; 
		// border-top: 1px solid $gray2;
		border-bottom: 1px solid $gray2;
		margin-top: 3px;
		li{
			display: inline-block;
			a{
				text-decoration: none;
				color: #000;
				font-family: $font2;
				font-size: 1em;
				text-transform: uppercase;
				@include anim();
				&:hover {
					color: $blue;
				}
			}
			&:after {
				font-family: $font2;
				content: '>';
				margin: 3px 10px 10px;
			}
		}
		li:last-child {
			a {
				font-weight: 600;
			}
			&:after {
				content: '';
				margin: 0;
			}
		}
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.breadcrumb-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.breadcrumb-alpha{
		.bread-list {
			padding: 14px 0;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $xlarge_bp) {
	.breadcrumb-alpha{
		border-top: 1px solid #d2d2d2;
	}
}
