/*------------------------------------*/
/*///////#content-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.content-alpha{
	margin: 20px 0 40px 0;
	font-weight: 300;
	font-family: $font1;
	font-weight: 300;
	font-size: 1em;
	color: #000;

	*{
		font-family: inherit;
		font-size: inherit;
		color: inherit;
	}

	b {
		font-weight: 600;
	}

	a {
		text-decoration: none;
		color: $blue;
		display: inline-block;
		@include anim();
		padding-left: 5px;
		padding-right: 5px;
		margin-left: -5px;
		margin-right: -5px;
		&:hover{
			background-color: $blue;
			color: #fff;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		column-count: 1;
		// padding-left: 20px;
		// margin: 0;
		list-style-position: inside;
		list-style-type: disc;
		// font-size: 12px;
		line-height: 1.4em;
		// padding: 0 1em;
		li{
			// display: block;
			line-height: 1.8em;
			margin: 0 0 0 1em;
			padding: 0 0 0 1em;
			text-indent: -2em;
			// &:before{
			// 	content: "•";
			// 	// display: inline-block;
			// 	height: 100%;
			// 	// font-size: 20px;
			// 	margin-right: 20px;
			// }
		}

	}

	// img{
	// 	display: block;
	// 	// margin: 2em 0;
	// }

	
	h1{
		font-family: $font2;
		font-weight: 400;
		color: $blue;
		font-size: 3.750em;
		line-height: 1em;
		padding: 1em 0 0em;
		margin:0;
	}
	h2{ 
		font-family: $font2;
		font-weight: 400;
		color: $blue;
		font-size: 2.5em;
		line-height: 1em;
		padding: 1em 0 0em;
		margin:0;
	}
	h3{
		font-family: $font2;
		font-weight: 400;
		color: $blue;
		font-size: 1.5em;
		line-height: 1em;
		padding: 1em 0 0em;
		margin:0;
	}
	h4{
		font-family: $font2;
		font-weight: 400;
		color: $blue;
		font-size: 1.200em;
		line-height: 1em;
		padding: 1em 0 0em;
		margin:0;
	}
	h5{
		font-family: $font2;
		font-weight: 400;
		font-size: 1.250em;
		line-height: 1.250em;
		// padding: 0em 0;
		margin:0;
	}
	h6 {
		font-family: $font2;
		font-weight: 400;
		font-size: 1.1em;
		line-height: 1.1em;
		padding: 1em 0 0em;
		margin:0;
	}

	
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.content-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.content-alpha{
		font-size: 1.250em;
		*{
			font-family: inherit;
			font-size: inherit;
			color: inherit;
		}
		h1{
			font-size: 4.750em;
			line-height: 1em;
		}
		h2{ 
			font-size: 3em;
			line-height: 1em;
		}
		h3{
			font-size: 2em;
			line-height: 1em;
		}
		h4{
			font-size: 1.500em;
			line-height: 1em;
		}
		ul {
			column-count: 1;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.content-alpha{
		ul {
			column-count: 1;
		}
		h1{
			font-size: 5.750em;
			line-height: 1em;
		}
		h2{ 
			font-size: 3.875em;
			line-height: 1em;
		}
		h3{
			font-size: 2.250em;
			line-height: 1em;
		}
		h4{
			font-size: 1.500em;
			line-height: 1em;
		}
	}
}
