/*------------------------------------*/
/*///////#controls-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.controls-alpha{
	.text {
		font-family: $font1;
		color: #000;
		font-size: 0.875em;
		font-weight: 500;
		padding: 12px 0;
	}
	a.listele {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: #d4d4d4;
		text-decoration: none;
		width: 34px;
		height: 34px;
		margin: 0 5px;
		border: 1px solid transparent;
		// @include anim();
		i{
			@include anim();
		}
		&.active {
			color: #000;
			border: 1px solid #d4d4d4;
		}
		&:hover {
			color: $blue;
			i{
				color: $blue;
				// transform: scale(1.4);
			}
		}
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.controls-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.controls-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.controls-alpha{

	}
}
