/*------------------------------------*/
/*///////#accordion-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.accordion-alpha{

	.accordion-item {
		margin-bottom: 10px;
		border: 1px solid $lightish;
		.accordion-title {
			padding-right: 35px;
			padding-left: 20px;
			border: none;
			font-family: $font1;
			text-decoration: none;
			font-size: 1.125em;
			line-height: 1.4em;
			@include anim();
			&:active, &:focus {
				background-color: transparent;
			}
			&:hover{
				background-color: transparent;
				color: #000;
			}
			&:before{
				// margin-top: -30px;
				// top: 30px;
				right: 20px;
				font-family: 'icons';
				@include anim();
				font-size: 13px;
				content: '\E003';
				// content: '<i class="icon-arrow-right"></i>';
			}
		}
		.accordion-content {
			border: none;
			padding: 20px;
			padding-top: 0;
			// p {
			// 	margin-top: 0;
			// 	font-family: $font1;
			// 	font-size: 1.125em;
			// }

		}

		&.is-active {
			.accordion-title {
				&:before{
					transform: rotate(-90deg);
					transform-origin: 50%;
				}

			}
		}
		
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.accordion-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.accordion-alpha{
		.accordion-item {
			.accordion-title {
				padding: 40px 150px 40px 70px;
				&:before{
					// margin-top: -30px;
					// top: 50%;
					right: 60px;
				}
			}
			.accordion-content {
				padding: 0px 70px 20px 70px;
				perspective: 600px;
				.content-alpha {
					@include anim(1s);
					opacity: 0;
					// transform-origin: 100%;
					// transform: rotateX( -90deg ); 
					// transition-delay: rotateX(0deg) 0.4s;
				}
			}
		}
		.accordion-item.is-active {
			.accordion-title {
				// padding: 40px 60px 20px 60px;
				padding: 40px 150px 10px 70px;
			}
			.accordion-content {
					// transform: perspective( 600px );
				.content-alpha {
					// transform: rotateX( 0deg ); 
					opacity: 1;
					// transition-delay: 0.6s;
				}
			}
		}

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.accordion-alpha{

	}
}
