/*------------------------------------*/
/*///////#form-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.form-alpha {
	position: fixed;
	@include anim(0.4s);
	right: - 100%;
	// top: 80px;
	top: 30%;
	width: calc(100% + 50px);
	z-index: 9;
	pointer-events: none;
	
	
	min-height: 300px;
	max-height: calc(100vh - 80px);
	max-height: calc(100% - 80px) !important;
	overflow: hidden;

	// .button-beta {
	// 	text-align: center;
	// 	display: inline-block;
	// }

	.form-row{
		opacity: 0;
		@include anim(0.3s);
		transition-delay: 0.3s;
		textarea{
			min-height: 5em;
		}
	}

	.form-cont {
		pointer-events: auto;
		// position: relative;
		background-color: #000;
		max-height: calc(100vh - 80px) !important;
		height: auto;
		margin-left: 50px; // for button space
		// height: 230px;
		// min-height: 300px;
		// max-height: calc(100vh - 80px);
		// max-height: calc(100% - 100px) !important;
		max-height: 100%;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 0px;
			background: transparent; /* make scrollbar transparent */
		}
		.scroll-content{
			pointer-events: auto;
			padding: 40px;
			// background-color: pink;
			// overflow: scroll;
		}
	}
	
	#formOpenClose {
		pointer-events: auto;
		position: absolute;
		z-index: 1;
		left: -220px;
		top: 0;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
		color: #fff;
		text-decoration: none;
		// padding: 18px 0;
		width: 230px;
		height: 40px;
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
		@include anim();
		.text{
			@include anim();
			display: flex;
			justify-content: center;
			align-items: center;
			// width: 100%;
			height: 100%;
			white-space: nowrap;
			padding-left: 20px ;
			background-color: #000;
		}
		.icon-cont {
			width: 40px;
			height: 40px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			align-self: flex-start;
			@include anim();
			background-color: #000;
			.icon-plus {
				@include anim();
			}
		}
		
		&:hover {
			.icon-cont, .text {
				background-color: $blue;
			}
		}
	}
	
	&.open {
		right: 0;
		top: 80px;
		.form-row {
			opacity: 1;
		}
		#formOpenClose{
			left: -180px;
			height: 50px;
			// width: 50px;
			// overflow: hidden;
			&:hover {
				.text {
					background-color: transparent;
				}
			}
			.text {
				opacity: 0;
				visibility: hidden;
				// display: none;
			}
			.icon-plus {
				-webkit-transform: rotate(135deg);
				transform: rotate(135deg);
				-webkit-transform-origin: 50% 50%; 
				transform-origin: 50% 50%; 
			}
		}
	}

	.form-title {
		color: #fff;
		font-family: $font2;
		font-size: 1.5em;
		font-weight: 600;
		margin: 0;
		margin-bottom: 20px;
	}

	.form-error {
		color: $alert;
		font-family: $font1;
		font-size: 0.750em;
		font-style: italic;
		font-weight: 600;
	}
	
	.input-alpha{
		line-height: 1em;
		input[type=text], 
		input[type=email], 
		select {
			margin: 0 0 0.7rem;
			padding: 0.3em 1em;
			height: 60px;
		}
	}

}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.form-alpha{
		width: 500px;
		right: -450px;
		max-height: calc(100vh - 80px);
		// top: 100px;
		.form-cont{
			// max-height: 100%;
			.scroll-content{
				padding: 50px;
			}
			#formOpenClose {
				height: 50px;
				left: -180px;
				.text {
					height: 100%;
					pointer-events: none;
				}
				.icon-cont {
					width: 50px;
					height: 50px;
				}
			}
		}

		&.open {
			right: 0;
			// right: calc(50px - 100%);
			left: auto;
			#formOpenClose{
				left: -180px;
			}
		}
		
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.form-alpha{
	}
}

@media (min-width: $xlarge_bp) {
	.form-alpha{
		top: 35%;
		&.open {
			top: 130px;
		}
	}
}
