/*------------------------------------*/
/*///////#card-delta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-delta{
	padding: 0;
	border: 1px solid $gray3;
	margin-bottom: 30px;
	min-height: 250px;
	.img-cont{
		text-align: center;
		position: relative;
		height: 150px;
		img{
			// max-height: 100px;
			text-align: center;
			position: relative;
			height: 150px;
			min-width: 100%;
		}
	}
	a.name {
		font-family: $font2;
		font-size: 1.5em;
		font-weight: 300;
		line-height: 1em;
		text-decoration: none;
		color: $blue;
		display: inline-block;
		// margin-top: 20px;
		padding: 20px;
	}

	.desc{
		padding: 20px;
		font-family: $font1;
		font-size: 0.875em;
		font-weight: 400;
		text-decoration: none;
		color: #000;
		display: inline-block;
		margin-bottom: 0px;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.card-delta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-delta{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-delta{

	}
}
