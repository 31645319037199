/*------------------------------------*/
/*///////#carousel-gamma ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.carousel-gamma{
	
	// margin-bottom: 40px;
	

	.icon-cont {
		i {
			color: #fff;
			font-size: 75px;
		}
	}

	.line {
		border: none;
		border-top: 1px solid #1274dd;
	}

	p {
		font-family: $font1;
		color: #fff;
		font-size: 1.125em;
		font-weight: 300;
		span {
			font-weight: 500;
		}
	}

	.swiper-slide {
		padding: 30px;
		box-sizing: border-box;
	}

	.carouselGamma {
		background-color: $blue;
		position: relative;
		// padding: 20px;
		overflow: hidden;
	}

	.swiper-pagination {
		margin-top: 20px;
		position: static;
		// bottom: -40px;
		// margin-bottom: 40px;
		// top: 0;
		// bottom: 0;
		text-align: left;
		span {
			width: 11px;
			height: 11px;
			margin: 0 5px;
		}
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.carousel-gamma{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.carousel-gamma{
		.carouselGamma{
			padding: 20px;
		}

		
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.carousel-gamma{
		.swiper-pagination {
			display: none;
		}
	}
}
