/*------------------------------------*/
/*///////#form-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.form-beta{
	// .title-alpha {
	// 	h2 {
	// 		margin: 0.8em 0 0 0;
	// 		font-weight: 600;
	// 	}
	// }
	.content-alpha {
		margin: 1em 0 1.4em 0;
	}

	p.form-question {
		color: #000;
		font-family: $font2;
		font-weight: 400;
		font-size: 1.2em;
		margin: 0.5em 0;
		&.margin{
			margin-top: 30px;
		}
	}
	p.form-info {
		font-family: $font1;
		font-weight: 300;
		font-size: 1.25em;
		margin-top: 0;
		margin-bottom: 40px;
		padding: 0;
	}
	.map-cont{
		height: 520px;
		margin-bottom: 30px;
	}
	.form-map{
		height: 100%;
	}
	.btn-cont{
		margin-top: 30px;
	}

}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.form-beta{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.form-beta{
		.content-alpha {
			margin: 1.6em 0 2em 0;
		}
		p.form-question {
			font-size: 1.5em;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.form-beta{

	}
}
