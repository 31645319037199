/*------------------------------------*/
/*///////#nav-gamma ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.nav-gamma{
	// position: -webkit-sticky;  // required for Safari
	// position: sticky;
	// top: 100px;
	.sticky-cont{
		border-bottom: 1px solid #d2d2d2;
	}

	a {
		display: flex;
		flex-flow: row;
		justify-content: flex-start;
		align-items: center;
		// background-color: pink;
		text-decoration: none;
		padding: 5px 0;
		font-family: $font1;
		font-weight: 600;
		font-size: 0.875em;
		// border-bottom: 1px solid #d2d2d2;
		.icon-cont{
			min-width: 75px;
			min-height: 45px;
			text-align: center;
			i{
				display: block;
				font-size: 44px;
				color: #000;
				padding: 0 15px;
			}
		}
		span{
			text-align: center;
			color: #000;
			margin-left: 10px;
			// font-family: $font1;
			// font-size: 1.250em;
		}
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.nav-gamma{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.nav-gamma{
		&.sticked{
			// width: 100%;
			// left: 0;
			height: 127px;
			
			@include anim();
			*{
				@include anim();
			}
			.sticky-cont{
				background-color: #fff;
				position: fixed;
				z-index: 9;
				margin: 0 auto;
				width: 1170px;
				border-bottom: 1px solid #d2d2d2;
				@include anim();
				top: 90px;
				a{
					padding: 10px 0 15px
				}
				.icon-cont{
					min-width: 40px;
					min-height: 20px;
					text-align: center;
					@include anim();
					i{
						display: block;
						font-size: 25px;
						// color: #000;
						padding: 0 15px;
						@include anim();
					}
				}
			}
		}

		// border-bottom: 1px solid #d2d2d2;
		a {
			border-bottom: none;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			padding: 10px 0 25px;
			@include anim();
			i {
				font-size: 44px;
				@include anim();
			}
			span {
				margin-left: 0px;
				text-align: center;
				@include anim();
			}
			&:hover {
				background-color: $blue;
				i, span {
					color: #fff;
				}
			}
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.nav-gamma{

	}
}
