/*------------------------------------*/
/*///////#grid-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.grid-alpha{
	.cell {
		display: inline-block;
		float: left;
		margin: 2% 0;
		height: 197px;
		width: 100%;
		&:first-child, &:nth-child(2) {
			width: 100%;
			height: 300px;
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	.hide{
		display: none;
	}
	
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.grid-alpha {

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.grid-alpha {
		.cell {
			// display: inline-block;
			// float: left;
			margin: 1%;
			width: 48%;
			height: 250px;
			&:first-child, &:nth-child(2) {
				width: 48%;
				height: 250px;
			}
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.grid-alpha{
		.cell {
			// display: inline-block;
			// float: left;
			margin: 0.585%;
			height: 197px;
			width: 23.8%;
			&:nth-child(2){
				height: 197px;
				width: 23.8%;
			}
			&:first-child {
				width: 48.7%;
				height: 405px;
			}
		}
	}
}
