/*------------------------------------*/
/*///////#subscribe-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.subscribe-alpha{
	position: relative;

	.line-cont{
		margin: 0;
		padding: 0;
	}
	.shortline{
		width: 30px;
		// height: 2px;
		border: none;
		border-top: 2px solid $blue;
	}

	input{
		font-family: $font2;
		font-weight: 400;
		font-size: 2.000em;
		color: $blue;
		height: 70px;
		padding: 10px 10px 10px 10px;
		// padding-right: 50px;
		text-align: center;
		border:0;
		border-bottom: 2px solid $gray;
		box-shadow:none;

		&::-ms-clear {
			display: none;
		}
		
		&:focus {
			box-shadow: none;
			border: none;
			border-bottom: 2px solid $blue;
			+ .subscribe i {
				color: $blue;
			}
			
			&::placeholder{
				color: transparent;
				// padding-left: 30px;
				transform: scale(0.9);
			}
		}
		&::placeholder {
			color: $blue;
			@include anim();
		}
	}

	.is-invalid-input{
		border:0;
		border-bottom: 2px solid $alert;
		background-color: #fff;
		color: $alert;
		&:focus{
			color: $blue;
		}
		+ .subscribe i {
			color: $alert;
		}
	}

	.subscribe {
		position: absolute;
		right: 0;
		top:0;
		// background-color: #fff;
		// background-color: pink;
		display: inline-block;
		padding: 25px 10px 24px;
		cursor: pointer;
		outline: none;
		i{
			color: $blue;
			font-size: 24px;
			@include anim();
		}
		&:hover{
			i{
				margin-right: -5px;
			}
		}
	}
	.loading{
		display: none;
		// z-index: 9;
		position: absolute;
		right: 0;
		top:5px;
		// background-color: #fff;
		// background-color: pink;
		padding: 15px 0px 5px;
	}
	.sending{
		.loading{
			display: inline-block;
		}
		.subscribe{
			display: none;
		}
	}

	.form-error{
		font-family: $font1;
		font-weight: 600;
		font-style: italic;
		text-align: center;
		@include anim();
		// padding-right: 60px;
		color: $alert;
	}



}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.subscribe-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.subscribe-alpha{

		input{
			height: 90px;
			font-size: 3em;
		}

		.subscribe {
			position: absolute;
			// background-color: red;
			display: inline-block;
			padding: 32px 10px 30px;
			cursor: pointer;
			outline: none;
			i{
				font-size: 30px;
				@include anim();
			}
		}
		.loading{
			padding: 30px 0px 5px;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.subscribe-alpha{

	}
}
