/*------------------------------------*/
/*///////#fourofour-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.fourofour-alpha{
	width: 100%;
	.blue-bg{
		background-color: $blue; 
	}
	// background-color: red;
	
	.text-cont{
		padding: 40px 30px;
		p {
			font-family: $font1;
			font-size: 1em;
			font-weight: 400;
			line-height: 1.6em;
			color: white;
			margin: 5px 0;
			padding: 0;
		}
	}

	

	.btn-cont {
		width: 220px;
		// .button-beta {

		// }
	}

	.btn-cont2{
		margin-top: 20px;
	}

	.error {
		display: block;
		text-align: center;
		font-family: $font2;
		font-size: 162px;
		font-weight: 500;
		color: white;
		margin: 0;
		padding: 0;
		// line-height: 1em;
		// margin-top: 60px;
		overflow: hidden;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.fourofour-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.fourofour-alpha{
		.error {
			font-size: 250px;
			text-align: left;
			margin-bottom: -50px;
		}
		.text-cont{
			padding: 40px 0px;
		}

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.fourofour-alpha{
		margin-top: 40px;
		.error {
			// display: inline-block;
			font-size: 390px;
			text-align: left;
			line-height: 1em;
			// user-select: none;
			// max-height: 400px;
			margin-bottom: -70px;
			margin-top: 40px;
			// z-index: -1;
			
		}
	}
}
