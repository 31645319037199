/*------------------------------------*/


/*////////// header-alpha ////////////*/


/*------------------------------------*/


/*<-- GLOBAL -->*/

.header-alpha {
	width: 100%;
	height: 80px;
	background-color: #fff;
	margin-top: -1px;
	display: flex;
	align-items: center; // &.sticked{
	// height: 80px;
	.stick-cont {
		padding: 15px 0;
		background-color: #fff;
		position: fixed;
		top: 0;
		width: 100%;
		height: 80px;
		z-index: 99;
		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
	}

	.shrinkOnSmall {
		display: inline-flex;
		width:0;
	}

	.main-logo {
		display: inline-block;
		width: 136px;
		// height: auto;
		img {
			// width: 196px;
			// height: auto;
		}
		&.shrinkit {
			width: 136px;
			height: auto;
		}
	}
	.menu-bars {
		margin-right: -20px;
		text-decoration: none;
		i {
			color: $blue;
			font-size: 20px;
		}
	}
	.mob-menubars-col{
		order: 4;
		margin-right: 30px;
		// padding: 0
	}
	.mob-search-col {
		order: 3;
		.mob-search-bt {
			text-decoration: none;
			&:focus{
				i{
					color: $blue;
				}
			}
			.icon-search {
				text-decoration: none;
				color: $blue;
				font-size: 20px;
			}
			.icon-close{
				text-decoration: none;
				color: $blue;
				display: none;
				font-size: 16px;
				padding-top: 3px;
			}
			&.closeActive{
				.icon-close{
					display: inline-block;
				}
				.icon-search{
					display: none;
				}
			}
		}
	}
	.mob-search-input-cont {
		position: fixed;
		width: 100%;
		top: -60px;
		left: 0;
		height: 45px;
		z-index: 2; // z-index: -1;
		@include anim(0.4s); // background-color: pink;
		.mob-search-input {
			padding: 0 10px 0 50px;
			outline: none;
			border: none;
			width: 100%;
			height: 45px;
			background-color: $gray; //  transparent;
			color: $blue;
			font-family: $font2;
			font-size: 1.250rem;
			box-shadow: none;
			@include anim(0.18s);
			&::placeholder {
				color: $blue;
				@include anim(0.18s);
			}
			&:focus {
				outline: none;
				box-shadow: none;
				&::placeholder {
					color: transparent;
					transform: scale(0.9);
				}
			}
		}
		&.open {
			// display: block;
			// left: 0;
			top: 79px;
		}
		.mob-search-bt-over {
			position: absolute;
			top: 8px;
			left: 15px;
			text-decoration: none;
			i {
				color: $blue;
				font-size: 1.250em;
			}
		}
	}
	.menu-cont {
		position: fixed;
		top: 0; // left: 0;
		left: -105%;
		width: 100%;
		height: 100%;
		background-color: $blue;
		z-index: 99; // display: none;
		// @include anim(0.3s);
		text-align: center;
		transition: left 0.3s cubic-bezier(.5, 0, .5, 1);
		overflow: auto;
		padding: 25px;
		&.open {
			left: 0;
		}
		.menu-close {
			text-decoration: none;
			position: absolute;
			top: 30px;
			right: 33px;
			i {
				color: #fff;
				font-size: 18px;
			}
		}
		.arsa-bt-cont {
			// margin-left: 25px;
			width: 100%;
			margin-top: 25px;
			text-align: center;
			.button-alpha {
				width: 180px;
				border: 1px solid #fff;
				color: #fff;
				padding: 13px 18px;
			}
		}
		.phone-cont {
			// margin-left: 25px;
			.phone-number {
				color: #fff;
				text-decoration: none;
				font-family: $font2;
				font-size: 1.250em;
				text-decoration: none;
			}
		}
		.menu-list {
			list-style: none;
			margin: 0;
			padding: 20px 10px;
			display: inline-flex;
			flex-flow: column;
			text-align: right;
			li {
				display: block;
				margin: 0 17px;
				text-align: left;
				@include anim(0.18s);
				a {
					position: relative;
					text-decoration: none;
					font-family: $font2;
					font-size: 1.250em;
					color: #fff;
					font-weight: 500;
					display: inline-block;
					padding: 0px;
					margin-bottom: 20px;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					span {
						width: 0;
						height: 2px;
						background-color: #fff;
						opacity: 0;
						position: absolute;
						left: 50%;
						top: 100%;
					}
				}
			} // li:last-child {
			// 	margin-right: 0;
			// }
			li.active,
			li:hover {
				a {
					color: #fff; // border-bottom: 2px solid $blue;
					span {
						height: 2px;
						width: 100%;
						top: 100%;
						left: 0;
						opacity: 1;
					}
				}
			}
		}
	}
	#topRow {
		display: none;
	}
	#append-search,
	#append-bt {
		display: none;
	}
}

// /*-- MD --*/
// @media screen and (min-width: $medium_bp) {
// 	.header-alpha {
// 	}
// }
// /*-- LG --*/
@media screen and (max-width: $xlarge_bp) {
	.belaliColumn{
		// visibility: hidden;
		order: 1;
	}
}
@media screen and (min-width: $xlarge_bp) {
	.header-alpha {
		height: 144px;
		.stick-cont {
			height: 144px;
			box-shadow: none;
			z-index: 99;
		}
		&.sticked {
			height: 144px; // prevent unwanted scrolling of content to top // js could be more accurate
			.stick-cont {
				// background-color: lightblue;
				height: 90px;
				position: fixed;
				top: 0;
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
			}
			.header-right {
				margin: 0;
			}
			#append-bt {
				padding: 0 20px;
			}
		}
		#append-search,
		#append-bt {
			&.showx {
				display: flex;
			} // &.hidex{
			// 	display: none;
			// }
		}
		#topRow {
			&.showx {
				display: flex;
			}
			.phone-cont {
				margin-left: 25px;
				.phone-number {
					color: $blue;
					text-decoration: none;
					font-family: $font2;
					font-size: 1.50em;
					text-decoration: none;
				}
			}
			.arsa-bt-cont {
				// margin-left: 25px;
				// margin-top: 25px;
				// width: 180px;
				padding: 0 10px 0 20px;
				.button-alpha {
					// border: 1px solid #fff;
					// color: #fff;
					padding: 11px 18px;
				}
			}
		}
		.stick-cont {
			padding: 16px 0;
			background-color: #fff;
			position: static; // height: 144px;
		}
		.main-logo {
			width: 250px;
			img {
				// height: auto;
			}
		}
		.mob-search-input-cont {
			display: none;
		}
		.mob-search-col {
			display: none;
		}
		.mob-menubars-col {
			display: none;

		}
		.menu-cont {
			position: static;
			// top: 0;
			// left: 0;
			width: 100%;
			height: 100%;
			text-align: right;
			// display: inline-block;
			background-color: transparent;
			overflow: visible;
			padding: 0px;
			.menu-close {
				display: none;
			}
			.arsa-bt-cont {
				display: none;
			}
			.phone-cont {
				display: none;
			}
			.menu-list {
				list-style: none;
				margin: 0;
				padding: 0;
				display: inline-flex;
				flex-flow: row; // text-align: right;
				li {
					display: inline-block;
					margin: 0 17px;
					text-align: left;
					@include anim(0.18s);
					a {
						color: #000;
						padding: 15px 0px;
						margin-bottom: 0px;
						white-space: nowrap;
						span {
							// z-index: -1;
							@include anim(0.18s);
							width: 0;
							height: 2px; // border: 1px solid $blue;
							background-color: $blue;
							opacity: 0;
							position: absolute;
							left: 50%;
							top: 100%;
						}
					}
				} // li:last-child {
				// 	margin-right: 0;
				// }
				li.active,
				li:hover {
					a {
						color: $blue; // border-bottom: 2px solid $blue;
						span {
							height: 2px;
							width: 100%;
							top: 100%;
							left: 0;
							opacity: 1;
						}
					}
				}
			} // end menu-list
		}
		.search-outer {
			height: 50px;
			width: 50px;
			z-index: 9;
			position: relative;
			display: inline-flex;
			
			.desk-search-close{
				position: absolute;
				text-decoration: none; 
				top: 14px;
				right: -20px;
				color: $blue;
				z-index: 10;
				opacity: 0;
				visibility: hidden;
				@include anim(0.2s);
				&:focus {
					color: $blue;
				}
			}
			.search-inner {
				// overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center; // justify-content: flex-start;
				// background-color: red;
				.desk-search-bt {
					position: absolute;
					left: 0;
					top: 0;
					float: left;
					width: 50px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 10;
					text-decoration: none;
					@include anim(0.4s); // top: 5px;
					// left: 20px;
					cursor: pointer;
					i {
						padding-top: 5px;
						color: $blue; // margin-top: 5px;
						font-size: 21px;
					}
				}
				#search-input {
					position: absolute;
					top: 0;
					right: -50px;
					padding: 10px 0;
					outline: none;
					border: none;
					width: 0px;
					height: 50px;
					background-color: $gray; //  transparent;
					color: $blue;
					font-family: $font2;
					font-size: 1.250rem;
					@include anim(0.4s);
					&::placeholder {
						color: $blue;
						@include anim(0.2s);
					}
					&:focus::placeholder {
						opacity: 0;
						transform: scale(0.9);
					}
				}
			}
			&.open {
				#search-input {
					width: 430px;
					padding-left: 10px;
					padding-right: 20px;
				}
				.desk-search-close{
					right: 15px;
					opacity: 1;
					visibility: visible;
				}
				.desk-search-bt {
					left: -430px;
					background-color: $gray;
				}
			}
		} // end search desk
	}
} // sticky staff
