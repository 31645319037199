/*------------------------------------*/
/*///////#carousel-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.carousel-beta{

}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.carousel-beta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.carousel-beta{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.carousel-beta{

	}
}
