/*------------------------------------*/
/*///////#footer-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.footer-alpha{
	margin-top: 60px;
	.sub-footer{
		padding: 25px 0;
	}
	.dustyline{
		border: none;
		margin:0;
		border-top: 1px solid $gray3;
	}
	.copy-col{
		order:2;
		text-align: center;
		.copyright{
			font-family: $font1;
			font-size: 0.750em;
			font-weight: 400;
			color: #6f7783;
			padding: 0;
			margin: 20px 0;
		}
	}
	.social-col{
		order:1;
		.social-list{
			text-align: center;
			padding: 0;
			margin: 20px 0;
			list-style: none;
			li{
				display: inline-block;
				margin: 0 8px;
				a{
					text-decoration: none;
					color: $blue;
				}
			}
		}
	}

	.gri-col{
		order:3;
		text-align: center;
		.gricreative{
			display: inline-block;
			padding: 0;
			margin: 20px 0;
		}
	}

	.footer-menu-list{
		list-style: none;
		margin: 0;
		padding: 10px 0 20px;
		li{
			display: block;
			margin: 0 10px;
			a{
				text-align: center;
				display: block;
				padding: 10px 0;
				color: $b;
				text-decoration: none;
				font-family: $font2;
				font-size: 1.250em;
				font-weight: 600;
			}
		}
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.footer-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.footer-alpha{
		margin-top: 130px;
		.social-col{
			order:2;
		}
		.gri-col{
			order:3;
			text-align: right;
		}
		.copy-col{
			order:1;
			text-align: left;
		}

		.footer-menu-list{
			li{
				display: inline-block;
				a{
					@include anim();
					&:hover{
						color: $blue;
					}
				}
			}
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.footer-alpha{

	}
}
