/*------------------------------------*/
/*///////#sidebar-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.sidebar-alpha{
	margin-top: -25px;
	select {
		border: none;
		background-color: #f2f6f9;
		color: #000;
		font-family: $font2;
		font-weight: 1.25em;
		padding: 10px 20px;
		height: 50px;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill:#005ec3'></polygon></svg>");
		background-size: 13px 9px;
		background-position: right -5px center;
	}
	.title{
		font-family: $font2;
		font-size: 1.500em;
		font-weight: 600;
		margin:  0 24px 0 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid $gray2;
		// margin: 0;
		// border-left: 1px solid $gray2;
		// border-right: 1px solid $gray2;
	}
	.side-list{
		margin: 0;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0;
		-webkit-padding-start: 0px;
		list-style: none;
		padding: 0;
		li{
			display: block;
			position: relative;
			padding: 0 1.875em;
			
			a{
				font-size: 1.125em;
				letter-spacing: 0.04em;
				font-family: $font2;
				font-weight: 600;
				display: block;
				text-decoration: none;
				color: #000;
				padding: 24px 0;
				// margin: 0 24px;
				@include anim();
				border-bottom: 1px solid $gray2;
				&:before {
					@include anim();
					content: '\25CF';
					font-size: 20px;
					position: absolute;
					top: 30%;
					left: -5px;
					opacity: 0;
				}
				
			}
			&.active{
				background-color: #f7f7f7;

				// a{
				// 	border-bottom:none;
				// }
				// + li {
				// 	a{
				// 		border-bottom: none;
				// 	}
				// }
				
				&:before{
					content: '\25CF';
					font-size: 20px;
					position: absolute;
					opacity: 1;
					top: 30%;
					left: -5px;
				}
			} 
			&:hover{
				
				background-color: #f7f7f7;
				a {
					color: $blue;
				}
				&:before{
					content: '\25CF';
					font-size: 20px;
					position: absolute;
					top: 30%;
					left: -5px;
					opacity: 1;
				}
			}
			
		}
		.last-space{
			display: block;
			border-bottom: 1px solid $gray2;
			height: 20px;
		}
		
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.sidebar-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.sidebar-alpha{
		margin-top: 20px;
		border-left: 1px solid $gray2;
		border-right: 1px solid $gray2;

		position: -webkit-sticky;  // required for Safari
		position: sticky;
		top: 110px;
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.sidebar-alpha{
		margin-right: 50px;
	}
}
