/*------------------------------------*/
/*///////#button-gamma ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.button-gamma{
	display: inline-flex;
	background-color: $blue;
	
	cursor: pointer;
	padding: 0.4rem 1.125rem;
	// padding-left: 5;
	// padding-right: 5;
	display: inline-flex;
	height: 50px;
	width: 50px;
	align-items: center;
	justify-content: center;
	@include anim(0.1s);
	text-decoration: none;
	border: 1px solid transparent;
	i{
		@include anim(0.17s);
		color: #fff;
		font-size: 20px;
	}
	&:hover, &:focus {
		border: 1px solid $blue;
		background-color: #fff;
		i{
			margin-top: -5px;
			color: $blue;
		}
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.button-gamma{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.button-gamma{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.button-gamma{

	}
}
