/*------------------------------------*/
/*///////#form-epsilon ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.form-epsilon{
	.title{
		font-family: $font2;
		font-size: 1.250em;
		font-weight: 400;
		line-height: 1.2em;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.form-epsilon{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.form-epsilon{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.form-epsilon{

	}
}
