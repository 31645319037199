/*------------------------------------*/
/*///////#page-projeler-detay ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.page-projeler-detay{
	.btn-cont{
		max-width: 220px;
		margin-top: 40px;
	}

	.map-cont {
		height: 400px;
	}
	.address-cont {
		margin-top: 20px;
		.title {
			margin-top: 30px;
			font-family: $font1;
			font-size: 1em;
			font-weight: 600;
			margin-bottom: 0;
		}
		p {
			font-family: $font1;
			font-size: 1em;
			font-weight: 300;
			margin: 0.3em 0;
			b{
				font-weight: 600;
			}
		}
		.mail{
			text-decoration: none;
			color: inherit;
			margin-left: 10px;
		}
	}
	.carousel-cont{
		.carousel-alpha, .carousel-delta {
			.swiper-slide{
				height: auto;
			}
		}
	}

	.title-alpha h2{
		margin-top: 1.2em;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.page-projeler-detay{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.page-projeler-detay{
		
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.page-projeler-detay{
		.carousel-cont{
			.carousel-alpha {
				.swiper-slide{
					height: 490px;
				}
			}
		}
	}
}
