/*------------------------------------*/
/*///////#page-projeler ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.page-projeler{
	margin-top: 30px;
	.card-cont{
		height: 220px;
		margin-bottom: 30px;
	}
	.nav-cont {
		// margin-top: 30px;
	}
	.iso-cont {
		margin-top: 50px;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.page-projeler{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.page-projeler{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.page-projeler{

	}
}
