//Its important to use bootstrap icons
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,500,700&amp;amp;subset=latin-ext');
// @import url('https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:200,300,300i,400,500,600,700&amp;amp;subset=latin-ext');

$icon-font-path: '../fonts/';

// Foundation
// @import "bower_components/foundation-sites/scss/foundation.scss";
@import "foundation.scss";
@import "lity.min.scss"; 
@import "vars.scss";
@import "icons.scss";
@import "mixin.scss";

@import "general.scss"; 




//Importing All Modules
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/accordion-alpha/accordion-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/address-alpha/address-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/banner-alpha/banner-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/banner-beta/banner-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/breadcrumb-alpha/breadcrumb-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/button-alpha/button-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/button-beta/button-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/button-gamma/button-gamma.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-alpha/card-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-beta/card-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-delta/card-delta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-epsilon/card-epsilon.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-gamma/card-gamma.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/card-zeta/card-zeta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/carousel-alpha/carousel-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/carousel-beta/carousel-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/carousel-gamma/carousel-gamma.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/content-alpha/content-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/controls-alpha/controls-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/footer-alpha/footer-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/form-alpha/form-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/form-beta/form-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/form-delta/form-delta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/form-epsilon/form-epsilon.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/form-gamma/form-gamma.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/fourofour-alpha/fourofour-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/grid-alpha/grid-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/header-alpha/header-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/input-alpha/input-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/iso-grid-alpha/iso-grid-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/isotope-alpha/isotope-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/map-alpha/map-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/map-beta/map-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/nav-beta/nav-beta.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/nav-gamma/nav-gamma.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/page-arsami-degerlendir/page-arsami-degerlendir.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/page-projeler-detay/page-projeler-detay.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/page-projeler/page-projeler.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/showcase-alpha/showcase-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/sidebar-alpha/sidebar-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/sitemap-alpha/sitemap-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/subscribe-alpha/subscribe-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/tag-alpha/tag-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/testimony-alpha/testimony-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/title-alpha/title-alpha.scss";
@import "D:/wwwroot/aydoganinsaat/HTML/app/modules/video-card-alpha/video-card-alpha.scss";




[tpl]{
  display: none !important;
}
