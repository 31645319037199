/*------------------------------------*/
/*///////#banner-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.banner-beta{
	
	.img-cont{
		z-index: -1;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0 -16px;
		height: 270px;
		@include anim();
	}

	.list-cont {
		position: relative;
		z-index: 1;
		margin-top: -20px;
		text-align: center;
		.nav-list {
			padding: 15px 20px;
			margin: 0 10px;
			list-style: none;
			background-color: #fff;
			border: 1px solid $gray2;
			display: block;
			text-align: center;
			li {
				display: block;
				text-align: left;
				a{
					display: inline-block;
					text-decoration: none;
					color: $blue;
					font-family: $font2;
					font-weight: 600;
					font-size: 1.375em;
					line-height: 1em;
					padding: 15px 0;
					
				}
			}
		}

	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.banner-beta{
	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.banner-beta{
		.img-cont{
			z-index: -1;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			margin: 0px;
			height: 400px;
		}

		.list-cont {
			
			margin-top: -45px;
			text-align: center;
			transition: width 0.3s ease-out;
			.nav-list {
				padding: 5px 10px;
				// margin: 0 40px;
				// transition: 0.3s width ease-out;
				// transition: width 0.3s ease-out;
				// transition: margin 0.3s ease-out;
				// transition: 0.1s margin ease-out;
				// display: inline-block;
				li {
					display: inline-block;
					margin: 0 10px;
					a{
						@include anim();
					}
				}
			}
			&.sticked {
				position: fixed;
				top: 90px;
				// left: 0;
				margin: 0 auto;
				width: 1170px;
				.nav-list {
					width: 100%;
					margin: 0;
				}
			}
		}

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.banner-beta{

	}
}
