/*------------------------------------*/
/*///////#card-gamma ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-gamma{
	padding: 20px 10px;
	border: 1px solid $gray3;
	.img-cont{
		text-align: center;
	}
	.link-list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: block;
		text-align: center;
		li{
			display: inline-block;
			font-family: $font2;
			font-size: 1.25em;
			font-weight: 400;
			a {
				display: inline-block;
				padding: 5px 13px;
				color: $blue;
				font-family: inherit;
				font-size: inherit;
				font-weight: inherit;
				line-height: 0.9em;
				text-decoration: none;
				border-right: 1px solid $gray3;
				
			}
		}
		li:last-child {
			a{
				border-right: none;
				// padding-right: 0;
			}
		}
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.card-gamma{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-gamma{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-gamma{

	}
}
