/*------------------------------------*/
/*///////#title-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.title-alpha{
	font-family: $font2;
	font-weight: 400;
	color: $blue;
	// *{
	// 	margin: 0;
	// }
	

	
	h1{
		font-size: 3.750em;
		line-height: 1em;
		margin: 0;
		font-weight: 400;
		// margin: 0.8em 0;
	}
	h2{ 
		font-size: 2.5em;
		line-height: 1em;
		margin: 0;
		font-weight: 400;
		// margin: 0.8em 0;
	}
	h3{
		font-size: 1.5em;
		line-height: 1em;
		margin: 0;
		font-weight: 400;
		// margin: 0.8em 0;
	}
	h4{
		font-size: 1.200em;
		line-height: 1em;
		margin: 0;
		font-weight: 400;
		// margin: 0.8em 0;
	}
	&.page-margins {
		float: none;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-top: 20px;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.title-alpha{
		
// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.title-alpha{
		h1{
			font-size: 4.750em;
			line-height: 1em;
		}
		h2{ 
			font-size: 3em;
			line-height: 1em;
		}
		h3{
			font-size: 2em;
			line-height: 1em;
		}
		h4{
			font-size: 1.500em;
			line-height: 1em;
		}
		&.page-margins {
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.title-alpha{
		h1{
			font-size: 5.750em;
			line-height: 1em;
		}
		h2{ 
			font-size: 3.875em;
			line-height: 1em;
		}
		h3{
			font-size: 2.250em;
			line-height: 1em;
		}
		h4{
			font-size: 1.500em;
			line-height: 1em;
		}
		&.page-margins {
			margin-top: 70px;
			margin-bottom: 45px;
		}
		
	}
}
