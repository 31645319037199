/*------------------------------------*/
/*///////#card-zeta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.card-zeta{
	border-bottom: 1px solid $gray3;
	padding: 30px 0;
	
	.img-cont{
		overflow: hidden;
		// border-radius: 100%;
		width: 137px;
		height: 137px;
		
	}
	img{
		max-width: 100%;
	}
	.title {
		font-family: $font2;
		font-weight: 400;
		font-size: 1.500em;
		color: $blue;
		margin: 0;
		margin-top: 20px;
		text-decoration: none
	}
	p {
		font-family: $font1;
		font-weight: 300;
		font-size: 1em;
		margin: 0.4em 0;
		color: black;
	}
	.hide{
		display: none;
	}
	a{
		text-decoration: none;
		color: $blue;
	}
	// .show {
	// 	display: flex;
	// }
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.card-zeta{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.card-zeta{
		border-bottom: 1px solid $gray3;
		border-top: 1px solid $gray3;
		padding: 30px 0;
		margin: 20px 0;

		img{
			max-width: 300px;
		}

		.title {
			font-family: $font2;
			font-size: 2em;
			color: $blue;
			margin: 0;
			// margin-top: 25px;
		}
		p {
			font-family: $font1;
			font-weight: 300;
			font-size: 1em;
			color: black;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.card-zeta{
		border-bottom: 1px solid $gray3;
		border-top: 1px solid $gray3;
		padding: 30px 0;
		margin: 10px 0;
		img{
			max-width: 250px;
		}
		.title {
			font-family: $font2;
			font-size: 2.250em;
			color: $blue;
			margin: 0;
			// margin-top: 25px;
		}
		p {
			font-family: $font1;
			font-weight: 300;
			font-size: 1.2em;
			color: black;
		}
	}
}
