/*------------------------------------*/
/*///////#map-beta ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.map-beta {
	width: 100%;
	height: 100%; 
	// #mapBeta {
	// 	width: 100%;
	// 	height: 100%; 
	// }

}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.map-beta{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.map-beta{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.map-beta{

	}
}
