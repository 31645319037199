/*------------------------------------*/
/*///////#video-card-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.video-card-alpha{
	
	border: 1px solid $gray3;
	
	.desc-cont {
		padding: 20px;
	}
	.img-cont{
		// display: flex;
		// justify-content: center;
		// align-items: center;
		display: block;

		text-align: center;
		min-height: 100px;
		width: 100%;
		position: relative;
		height: 150px;
		img{
			// max-height: 100px;
			text-align: center;
			position: relative;
			height: 150px;
			min-width: 100%;
		}
		.play-bt {
			position: absolute;
			z-index: 1;
			margin: 50px auto;
			width: 64px;
			height: 64px;
			padding: 20px;
			left: 0;
			right: 0;
			background-color: rgba(20, 121, 229, 0.5); 
			display: inline-block;
			// display: inline-flex;
			// justify-content: center;
			// align-items: center;
			// align-self: center;
			text-align: center;
			border-radius: 100%;
			text-decoration: none;
			@include anim();
			&:hover {
				background-color: rgba(20, 121, 229, 1); 
			}
			i{
				color: #fff;
			}
		}
	}
	a.name {
		font-family: $font2;
		font-size: 1.5em;
		font-weight: 300;
		line-height: 1em;
		text-decoration: none;
		color: $blue;
		display: block;
		// margin-top: 20px;
	}

	.desc{
		font-family: $font1;
		font-size: 0.875em;
		font-weight: 400;
		text-decoration: none;
		color: #000;
		display: inline-block;
		margin-bottom: 0px;
	}
}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.video-card-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.video-card-alpha{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.video-card-alpha{

	}
}
