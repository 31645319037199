/*------------------------------------*/
/*///////#showcase-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.showcase-alpha {
	// background-color: pink;
	padding-top: 60px;
	padding-bottom: 60px;

	// background-image: url('../images/showcase-bg.jpg');
	background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
	
	.title {
		font-family: $font2;
		font-weight: 300;
		font-size: 2.6em;
		padding: 0;
		color: #000;
		margin: 0.2em 0;
	}
	.line-cont {
		display: block;
		.shortline {
			margin: 0;
			padding: 0;
			border: none;
			border-top: 3px solid $blue;
			width: 110px;
		}
	}

	.grid {
		.grid-item {
			// float: left;
			width: 550px;
			// margin-bottom: 30px;
		}
	}

	p.desc {
		font-family: $font1;
		font-size: 1.125em;
		line-height: 1.6em;
	}

	.bt1 {
		margin-top: 40px;
		display: block;
	}

	.bt2 {
		display: none;
	}

	.p1 {
		margin-bottom: 20px;
	}

	.p2 {
		display: none;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.marginControl {
		margin-bottom: 30px;
	}

	.marginControl2 {
		margin-bottom: 30px;
	}

	.col1 {
		order:2;
	}

	.col2 {
		order:1;
	}

}

/*<-- SM -->*/
// @media (min-width: $small_bp) {
// 	.showcase-alpha{

// 	}
// }

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.showcase-alpha{
		padding-top: 80px;
		padding-bottom: 80px;
		background-image: url('../images/showcase-bg.jpg');
		background-attachment: fixed;

		.line-cont {
			.shortline {
				width: 35px;
			}
		}


		.col1 {
			order:1;
		}
	
		.col2 {
			order:2;
		}

		.marginControl{
			margin-top:80px;
		}
		.bt1 {
			display: none;
		}
	
		.bt2 {
			display: block;
		}
		.p1 {
			margin-bottom: 65px;
		}
	
		.p2 {
			display: block;
			margin-top: 90px;
		}
		
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.showcase-alpha {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
