$blue: #005ec3;  
$blue2: #074383;
$black: #000000;
$b: #000000;
$gray: #eeeeee;
$gray2: #d2d2d2;
$gray3: #e2e2e2;
$darkish: #1b1b1c; 
$lightish: #b2c2d5;
$alert: #dd5d5d;
$success: #00c30e; 

$font1: 'Open Sans', sans-serif;
$font2: 'Saira Extra Condensed', sans-serif;


body {
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

// breakpoints
$small_bp: 0;
$medium_bp: 640px;
$large_bp: 1024px;
$xlarge_bp: 1200px;