/*------------------------------------*/
/*///////#form-gamma ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.form-gamma{
	padding-top: 10px;
	.title-alpha h2{
		margin: 0.6em 0 0.1em;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.form-gamma{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.form-gamma{

	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.form-gamma{

	}
}
