/*------------------------------------*/
/*///////#banner-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.banner-alpha{
	background: $blue;
	background: linear-gradient(to right, $blue, #228bfc);
	color: #fff;
	padding: 65px 0 65px 20px;
	
	
	h2{
		font-family: $font2;
		font-weight: 100;
		font-size: 55px;
		padding: 0;
		margin: 0;
		line-height: 1em;
	}

}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.banner-alpha{
		
	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.banner-alpha{
		padding: 65px 0 65px 65px;
		h2{
			// font-family: $font2;
			// font-weight: 400;
			font-size: 90px;
			// padding: 0;
			// margin: 0;
			// line-height: 1em;
		}
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.banner-alpha{
		
	}
}
