@mixin cover-background($img-uri){
    background: url($img-uri) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin anim($duration:0.18s){
    transition: $duration all cubic-bezier(.5, 0, .5, 1);
}



.clearfix:after {
    content: "";
    display: table;
    clear: both;
}