@font-face {
	font-family: "icons";
	src: url('../fonts/icons/icons.eot');
	src: url('../fonts/icons/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/icons.woff2') format('woff2'),
		url('../fonts/icons/icons.woff') format('woff'),
		url('../fonts/icons/icons.ttf') format('truetype'),
		url('../fonts/icons/icons.svg#icons') format('svg');
}

@mixin icon-styles {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == arrow-bold-right {
		$char: "\E001";
	}
	@if $filename == arrow-bold-up {
		$char: "\E002";
	}
	@if $filename == arrow-down {
		$char: "\E003";
	}
	@if $filename == arrow-left {
		$char: "\E004";
	}
	@if $filename == arrow-right {
		$char: "\E005";
	}
	@if $filename == arrow-up-up {
		$char: "\E006";
	}
	@if $filename == arrow-up {
		$char: "\E007";
	}
	@if $filename == book {
		$char: "\E008";
	}
	@if $filename == building {
		$char: "\E009";
	}
	@if $filename == check {
		$char: "\E00A";
	}
	@if $filename == check2 {
		$char: "\E00B";
	}
	@if $filename == close {
		$char: "\E00C";
	}
	@if $filename == error {
		$char: "\E00D";
	}
	@if $filename == facebook {
		$char: "\E00E";
	}
	@if $filename == floor {
		$char: "\E00F";
	}
	@if $filename == glass {
		$char: "\E010";
	}
	@if $filename == handshake {
		$char: "\E011";
	}
	@if $filename == instagram {
		$char: "\E012";
	}
	@if $filename == listele-a {
		$char: "\E013";
	}
	@if $filename == listele-b {
		$char: "\E014";
	}
	@if $filename == measure {
		$char: "\E015";
	}
	@if $filename == menubars {
		$char: "\E016";
	}
	@if $filename == menuclose {
		$char: "\E017";
	}
	@if $filename == nokta {
		$char: "\E018";
	}
	@if $filename == pen {
		$char: "\E019";
	}
	@if $filename == picture {
		$char: "\E01A";
	}
	@if $filename == pin {
		$char: "\E01B";
	}
	@if $filename == play {
		$char: "\E01C";
	}
	@if $filename == plus {
		$char: "\E01D";
	}
	@if $filename == search {
		$char: "\E01E";
	}
	@if $filename == twitter {
		$char: "\E01F";
	}
	@if $filename == writing {
		$char: "\E020";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-arrow-bold-right {
	@include icon(arrow-bold-right);
}
.icon-arrow-bold-up {
	@include icon(arrow-bold-up);
}
.icon-arrow-down {
	@include icon(arrow-down);
}
.icon-arrow-left {
	@include icon(arrow-left);
}
.icon-arrow-right {
	@include icon(arrow-right);
}
.icon-arrow-up-up {
	@include icon(arrow-up-up);
}
.icon-arrow-up {
	@include icon(arrow-up);
}
.icon-book {
	@include icon(book);
}
.icon-building {
	@include icon(building);
}
.icon-check {
	@include icon(check);
}
.icon-check2 {
	@include icon(check2);
}
.icon-close {
	@include icon(close);
}
.icon-error {
	@include icon(error);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-floor {
	@include icon(floor);
}
.icon-glass {
	@include icon(glass);
}
.icon-handshake {
	@include icon(handshake);
}
.icon-instagram {
	@include icon(instagram);
}
.icon-listele-a {
	@include icon(listele-a);
}
.icon-listele-b {
	@include icon(listele-b);
}
.icon-measure {
	@include icon(measure);
}
.icon-menubars {
	@include icon(menubars);
}
.icon-menuclose {
	@include icon(menuclose);
}
.icon-nokta {
	@include icon(nokta);
}
.icon-pen {
	@include icon(pen);
}
.icon-picture {
	@include icon(picture);
}
.icon-pin {
	@include icon(pin);
}
.icon-play {
	@include icon(play);
}
.icon-plus {
	@include icon(plus);
}
.icon-search {
	@include icon(search);
}
.icon-twitter {
	@include icon(twitter);
}
.icon-writing {
	@include icon(writing);
}
