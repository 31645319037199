/*------------------------------------*/
/*///////#map-alpha ///////////*/
/*------------------------------------*/

/*<-- GLOBAL -->*/
.map-alpha{
	width: 100%;
	height: 500px;
	// background-color: pink;
	.map{
		height: 100%;
	}

	.infoWindow {
		font-family: 'regular';
		width: 229px;
		color: #737373;
		font-size: 12px;
		background-color: #fff;
		position: relative;
	}
	
	.infoWindow{
		font-family:'regular';width:229px;color:#737373;font-size:12px;background-color:#fff;position:relative;
	}
	.infoWindow h3{
		margin: 0;
		padding: 0;
		font-family: $font2;
		font-size:20px;
		line-height:20px;
		color:$blue;
		padding-bottom:14px;
		border-bottom:1px solid #d3d2d2;
		
		// margin:5px 0 10px 0;
	}
	.infoContent{padding:20px}
	
	
	.gm-style div{font-family:$font2 !important;}
	.gm-style>div:first-child>div+div>div:last-child>div>div:first-child>div{background-color:transparent !important;box-shadow:none !important;width:auto !important;height:auto !important;}.gm-style>div:first-child>div+div>div:last-child>div>div:first-child>div>div>div{background-color:#fff !important;box-shadow:none !important;}
	.gm-style>div:first-child>div+div>div:last-child>div>div:last-child{margin-right:20px;margin-top:14px;}
	.gm-style-iw{top:22px !important;left:22px !important;}
	
	
	
	.infoWindow p{color:#737373; padding-bottom:0px;}
	.infoWindow.infproject h3{margin:0; font-size:20px !important;line-height:18px !important;}
	.infoWindow.infproject p{display: none; margin-bottom:14px;border-bottom:1px solid #d3d2d2;}
	.infoWindow.infarchitect p{font-size:16px}
	.infoWindow .link{
		font-family: $font2;
		position: relative;
		text-decoration: none;
		color:#000;
		font-size:18px;
		margin:5px 0;
		display:block;}
	.infoWindow .link:after{
		position: absolute;
		right: 0;
		top: 8px;
		color:#000;
		font-size:13px;
		font-family:"icons";
		content:"\E005";
		margin:0;
		-webkit-transition:all .4s linear 0s;-moz-transition:all .4s linear 0s;-o-transition:all .4s linear 0s;transition:all .4s linear 0s;}
	.infoWindow .link:hover,
	.infoWindow .link:hover:after{color:$blue;}
	.infoContent{
		padding:20px;
	}
}

/*<-- SM -->*/
@media (min-width: $small_bp) {
	.map-alpha{

	}
}

/*<-- MD -->*/
@media (min-width: $medium_bp) {
	.map-alpha{
		height: 500px;
	}
}

/*<-- LG -->*/
@media (min-width: $large_bp) {
	.map-alpha{
		height: 690px;
	}
}
